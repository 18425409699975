import React from "react"

import Layout from "../../components/layout"
import BlogHome from "../../components/blog/blogHome"

const IndexPage = () => {
  return (
    <Layout>
      <BlogHome />
    </Layout>
  )
}

export default IndexPage
