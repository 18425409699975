import React from "react"
import styled from "styled-components"

const blogDetails = () => (
  <Details>
    <div className="wrapper">
      <p className="display-1">
        Looking to learn? We're writing on topics like...
      </p>
      <div className="blog-deet">
        <p className="deet-header">Education</p>
        <p>
          Pedagogy is a continually evolving field. You know to stay top of your
          game as an educator it's important to learn about new strategies, try
          different methods of instruction, and see what works best for you.
        </p>
        <a href="#education" className="btn-left">
          Posts on education &gt;
        </a>
      </div>
      <div className="blog-deet">
        <p className="deet-header">Tech Integration</p>
        <p>
          Technology should never be the focus of a lesson, but rather a tool to
          reach your lesson's outcomes. We've got great ideas on ways of
          incorporating technology into your existing teaching practices.
        </p>
        <a href="#tech integration" className="btn-left">
          Posts on Tech Integration &gt;
        </a>
      </div>
      <div className="blog-deet">
        <p className="deet-header">Computer Science</p>
        <p>
          Whether you're a veteran developer turned teacher or an administrator
          tapped [lucky] you to teach a course this year: we break down computer
          science and development into manageable chunks that's accessible to
          students.
        </p>
        <a href="#Computer Science" className="btn-left">
          Posts on Computer Science &gt;
        </a>
      </div>
    </div>
  </Details>
)

const Details = styled.div`
  background: var(--grey);
  padding-bottom: 200px;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
  min-height: 50vh;
  margin-left: 100px;
  margin-right: 100px;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    margin-left: 20px;
    margin-right: 20px;
    
    .display-1 {
      font-size: 2.4rem;
      text-align: left;
    }
  }

  }

  .display-1 {
    grid-column: 1/-1;
    text-align: center;
  }

  .deet-header {
    font-weight: 600;
    font-size: 18px;
  }

  .blog-deet {
    display: grid;
    border-top: 5px solid var(--black);
    align-content: space-between;
  }

  a {
    text-decoration: none;
    color: var(--blue-200);
    transition: ease-in-out .4s;
  }
  
  a:hover {
    color: var(--teal);
  }
`

export default blogDetails
