import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { motion } from "framer-motion"

const contentCard = ({ content }) => {
  let featuredImgFluid =
    content.node.frontmatter.featuredImage.childImageSharp.fluid
  return (
    <motion.div whileHover={{ y: -3 }} transition={{ duration: 0.4 }}>
      <BackgroundImage className="content-card" fluid={featuredImgFluid}>
        <Link
          style={{ textDecoration: `none`, borderRadius: `8px` }}
          to={`/posts${content.node.frontmatter.slug}`}
        >
          <CardWrapper>
            <h2 className="lead">{content.node.frontmatter.title}</h2>
            <p>{content.node.excerpt}</p>
            <TagType>
              <p>{content.node.frontmatter.tag.toUpperCase()}</p>
            </TagType>
          </CardWrapper>
        </Link>
      </BackgroundImage>
    </motion.div>
  )
}

const CardWrapper = styled.div`
  display: grid;
  padding: 20px;
  border-radius: 8px !important;
  min-height: 100% !important;
  align-content: space-between;
  color: white;
  position: relative;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7));

  a {
    text-decoration: none;
    color: var(--white);
  }

  h2 {
    margin-bottom: 20px;
  }
`

const TagType = styled.div`
  background: var(--teal);
  border-radius: 8px;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 200px;
  text-align: center;

  p {
    font-size: 12px;
    padding-left: 0;
    font-weight: 400;
    text-transform: uppercase;
  }
`

export default contentCard
