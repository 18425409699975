import React from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"

import BlogSection from "./blogSection"
import BlogDetails from "./blogDetails"
import LatestContent from "./latestContent"

const tagMasterList = [`education`, `tech integration`, `Computer Science`]

const listing = () => (
  <>
    <motion.div
      initial={{
        x: -300,
      }}
      animate={{
        x: 0,
      }}
      transition={{
        duration: 0.5,
      }}
    >
      <LatestContent />
      <BlogDetails />
      {tagMasterList.map(topic => (
        <BlogSection tag={topic} />
      ))}
    </motion.div>
  </>
)

export default listing
