import React from "react"
import { StaticQuery, graphql } from "gatsby"
import styled from "styled-components"

import LatestCard from "./latestCard"

const LATEST_POSTS_QUERY = graphql`
  query latestContentQuery {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "blog" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
      limit: 3
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            tag
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            slug
            date(formatString: "MMMM DD, YYYY")
            author
          }
        }
      }
    }
  }
`

const latestContent = () => (
  <>
    <div>
      <h1 className="lead" style={{ marginLeft: `50px`, marginTop: `50px` }}>
        Our Latest Posts
      </h1>
    </div>
    <LatestContent>
      <div id="latestCards">
        <StaticQuery
          query={LATEST_POSTS_QUERY}
          render={({ allMarkdownRemark }) =>
            allMarkdownRemark.edges.map(edge => <LatestCard content={edge} />)
          }
        />
      </div>
    </LatestContent>
  </>
)

// Styled components
const LatestContent = styled.div`
  display: grid;
  min-height: 75vh;
  grid-template-columns: 1fr;
  grid-gap: 40px;
  grid-row-gap: 40px;
  margin: 50px 50px 100px;
  align-items: start;

  #latestCards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;

    @media (max-width: 1000px) {
      grid-template-columns: 1fr;
    }
  }

  #latestCards > div:first-child {
    grid-row: 1 / span 2;
    div {
      min-height: 100%;
    }
  }
`

export default latestContent
