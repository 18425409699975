import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import { motion } from "framer-motion"

import LatestCard from "./latestCard"
import headerImg from "../../images/eduHeader.svg"

const ALL_POSTS_QUERY = graphql`
  query allPostsQuery {
    allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "blog" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            tag
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            slug
            date(formatString: "MMMM DD, YYYY")
            author
          }
        }
      }
    }
  }
`

const blogSection = ({ tag }) => (
  <BlogSectionWrapper id={tag}>
    <SectionHeader>
      <img src={headerImg} alt="Header Image" />
      <h1 className="display-1">{tag.toUpperCase()}</h1>
    </SectionHeader>
    <SectionGallery>
      <StaticQuery
        query={ALL_POSTS_QUERY}
        render={({ allMarkdownRemark }) =>
          allMarkdownRemark.edges
            .filter(edge => edge.node.frontmatter.tag == tag)
            .map(edge => <LatestCard content={edge} />)
        }
      />
    </SectionGallery>
  </BlogSectionWrapper>
)

// Styled components
const BlogSectionWrapper = styled.div`
  display: grid;
`
const SectionHeader = styled.div`
  min-height: 200px;
  background: var(--blue-200);
  display: grid;
  grid-template-columns: 1fr 1fr;
  place-items: center center;
  color: var(--white);

  img {
    position: relative;
    width: 40%;
    height: auto;
    margin-top: -120px;
  }

  @media (max-width: 800px) {
    .display-1 {
      font-size: 2rem;
    }

    img {
      width: 80%;
    }
  }
`

const SectionGallery = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
  margin: 150px;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
    margin-top: 150px;
    margin-left: 50px;
    margin-right: 50px;
  }
`

export default blogSection
